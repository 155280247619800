import React, { useState, useEffect, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';

import { ModalPortal, Button, Form, FieldRadioButton, NamedLink } from '../../components';
import { createCreditsPurchasingPaylink, updateInAppPurchaseCredits } from '../../util/api';
import {
    IconLockS,
    MasterCardOldPaymentIcon,
    VisaOldPaymentIconCHF,
    ApplePayPaymentIcon,
    GooglePayPaymentIcon,
    VisaPaymentIcon,
    MasterCardPaymentIcon,
    TwintPaymentIcon,
    PostFinancePaymentIcon,
    VisaOldPaymentIconEUR,
    PayPalPaymentIcon,
} from '../../icons';
import { getUserCountry } from '../../util/location';
import config from '../../config';

import css from './CreditsPurchasingModal.css';
import { checkIOS } from '../../util/userAgent';
import { CREDITS_PACKAGE_1, CREDITS_PACKAGE_2, CREDITS_PACKAGE_3 } from '../../credits-config';
import {
    checkIAPTransactionMessage,
    checkIfCreditsProduct,
    getMarketplaceProductId,
    postIAPMessage,
} from '../../util/ios';

const {
    maps: { supportedCountries },
} = config;

export default ({
    isOpen,
    onClose,
    heading = 'Credits hinzufügen',
    description = 'Wähle die Anzahl Credits',
    sidenote = null,
    onError = () => null,
    currentUser,
}) => {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    const actionSectionRef = useRef();

    const userCountry = getUserCountry();
    const defaultcurrency = 'CHF';

    const { currency = defaultcurrency } = supportedCountries[userCountry];

    const commonIcons = [
        <MasterCardPaymentIcon />,
        <VisaPaymentIcon />,
        <GooglePayPaymentIcon />,
        <ApplePayPaymentIcon />,
        <MasterCardOldPaymentIcon />,
    ];

    const iconsListConfig = {
        CHF: [
            <TwintPaymentIcon />,
            <PostFinancePaymentIcon />,
            ...commonIcons,
            <VisaOldPaymentIconCHF />,
        ],
        EUR: [<PayPalPaymentIcon />, ...commonIcons, <VisaOldPaymentIconEUR />],
    };

    const handlePayLinkCreation = async packageId => {
        setLoading(true);

        const { origin, pathname } = window.location;

        try {
            const neededParams = {
                packageId,
                referenceId: currentUser.id.uuid,
                redirectURLSuccess: `${origin + pathname}?paymentStatus=success`,
                redirectURLFailure: `${origin + pathname}?paymentStatus=failed`,
                userCountry,
                userEmail: currentUser.attributes.email,
            };

            const payLinkResponse = await createCreditsPurchasingPaylink(neededParams);

            if (payLinkResponse?.success) {
                window.open(payLinkResponse.payLink, '_top');
            } else {
                onError('paymentFailed');
            }
        } catch (err) {
            onError('paymentFailed');
        } finally {
            setLoading(false);
        }
    };

    const handleInAppPurchaseCredits = async e => {
        const messageData = checkIAPTransactionMessage(e);

        if (!messageData) return;

        const { productId, transactionId } = messageData;

        const marketplaceProductId = getMarketplaceProductId(productId);
        const isCreditsProduct = checkIfCreditsProduct(marketplaceProductId);

        if (!isCreditsProduct) return;

        const response = await updateInAppPurchaseCredits(transactionId);
        const data = await response.json();

        setLoading(false);

        if (data.error) {
            return onError('paymentFailed');
        }

        setReady(true);

        const { location } = window;
        const { origin, pathname } = location;
        location.replace(origin + pathname);
    };

    const handleCreditsPurchase = ({ packageId }) => {
        const { iOS } = checkIOS();
        const isNative = window.ReactNativeWebView;

        if (isNative && iOS) {
            setLoading(true);
            postIAPMessage(packageId, () => onError('iapIdMissed'));
        } else {
            handlePayLinkCreation(packageId);
        }
    };

    const scrollToActionSection = () => {
        if (!actionSectionRef.current) return;

        actionSectionRef.current.scrollIntoView({
            block: 'end',
            inline: 'end',
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        /**
         * Listen to WebView messages
         */
        const { iOS } = checkIOS();
        const isNative = window.ReactNativeWebView;

        if (isNative && iOS) {
            document.addEventListener('message', handleInAppPurchaseCredits);
            window.addEventListener('message', handleInAppPurchaseCredits);
        }

        return function() {
            document.removeEventListener('message', handleInAppPurchaseCredits);
            window.removeEventListener('message', handleInAppPurchaseCredits);
        };
    }, []);

    return (
        <ModalPortal
            id="edit-image-description"
            isOpen={isOpen}
            onClose={loading ? () => null : onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <h3 className={css.modalHeading}>{heading}</h3>
            <p className={css.modalDesc}>{description}</p>
            {sidenote}

            <FinalForm
                initialValues={{
                    packageId: CREDITS_PACKAGE_1,
                }}
                onSubmit={handleCreditsPurchase}
                render={fieldRenderProps => {
                    const { form, values, handleSubmit } = fieldRenderProps;
                    const { packageId } = values || {};

                    const creditsOptions = [
                        { price: '8.90', creditsToPurchase: 30, id: CREDITS_PACKAGE_1 },
                        {
                            price: '29.90',
                            creditsToPurchase: 150,
                            spare: '33',
                            id: CREDITS_PACKAGE_2,
                        },
                        {
                            price: '44.90',
                            creditsToPurchase: 300,
                            spare: '50',
                            id: CREDITS_PACKAGE_3,
                        },
                    ];

                    const { price } = creditsOptions.find(({ id }) => packageId === id);

                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className={css.form}>
                                {creditsOptions.map(
                                    ({ price, creditsToPurchase, spare, id }, index) => (
                                        <FieldRadioButton
                                            key={index}
                                            form={form}
                                            className={css.field}
                                            id={id}
                                            name="packageId"
                                            value={id}
                                            notifyOnChange={scrollToActionSection}
                                            label={
                                                <div className={css.option}>
                                                    <span className={css.creditsToPurchase}>
                                                        {creditsToPurchase} Credits
                                                    </span>
                                                    <div className={css.subOption}>
                                                        <span className={css.price}>
                                                            {price} {currency}
                                                        </span>
                                                        {spare && (
                                                            <span className={css.spare}>
                                                                (spare {spare}%)
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )
                                )}
                            </div>
                            <div className={css.subTotal}>
                                <div className={css.subTotalHeading}>
                                    <p>Gesamttotal</p>
                                    <p>Einmalige Zahlung</p>
                                </div>
                                <div className={css.subTotalPricing}>
                                    {price} {currency}
                                </div>
                            </div>

                            <div className={css.paymentOptions}>
                                <p>
                                    <IconLockS />
                                    Sichere Zahlung
                                </p>
                                <div className={css.paymentMethodsInfo}>
                                    {iconsListConfig[currency]}
                                </div>
                            </div>

                            <p className={css.sidenote}>
                                Geschützt durch unsere{' '}
                                <NamedLink name="GuaranteePage" openInNewTab>
                                    Zufriedenheitsgarantie
                                </NamedLink>
                                .
                            </p>

                            <Button
                                inProgress={loading}
                                disabled={loading || ready}
                                className={css.actionButton}
                                ready={ready}
                                buttonRef={actionSectionRef}
                            >
                                Bezahlung
                            </Button>
                        </Form>
                    );
                }}
            />
        </ModalPortal>
    );
};
